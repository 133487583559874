import React, { useState } from "react"
import BoxStyled from "./styles/boxStyles"
import Button from "./button"
import Img from "gatsby-image"
import VisibilitySensor from "react-visibility-sensor"
import { Link } from "gatsby"

const Box = ({
  heading,
  description,
  background,
  image,
  buttonColor,
  buttonColorHover,
  buttonText,
  slug,
  url,
}) => {
  const [isVisible, setVisibility] = useState(false)
  const onChange = visiblity => {
    if (!!visiblity) {
      setVisibility(visiblity)
    }
  }

  return (
    <VisibilitySensor scrollCheck={true} partialVisibility onChange={onChange}>
      <BoxStyled className={` ${isVisible ? "show" : ""}`}>
        <div className="box__header">
          {url ? (
            <Link to={url}>
              <div className="image">
                <Img
                  fixed={background.localFile.childImageSharp.fixed}
                  alt={heading}
                  className="box__background"
                />
                {image && (
                  <Img
                    fixed={image.localFile.childImageSharp.fixed}
                    alt={heading}
                    className="box__logo"
                  />
                )}
              </div>
            </Link>
          ) : (
            <div className="image">
              <Img
                fixed={background.localFile.childImageSharp.fixed}
                alt={heading}
                className="box__background"
              />
              {image && (
                <Img
                  fixed={image.localFile.childImageSharp.fixed}
                  alt={heading}
                  className="box__logo"
                />
              )}
            </div>
          )}
        </div>
        <div className="box__body">
          <p className="box__heding">
            {url ? <Link to={url}>{heading}</Link> : heading}
          </p>
          <div
            className="box__description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          {url ? (
            <div className="box__button">
              <Button
                text={buttonText}
                link={url}
                color={buttonColor}
                hover_color={buttonColorHover}
                position="center"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </BoxStyled>
    </VisibilitySensor>
  )
}

export default Box
