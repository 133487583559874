import styled from "styled-components"

const CaseStusdiesStyled = styled.div`
  position: relative;
  .caseStudies {
    &__wapper {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 100px;
      @media (max-width: 940px) {
        justify-content: center;
      }
      & > div:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
`

export default CaseStusdiesStyled
