import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import CaseStusdiesStyled from "../components/styles/CaseStusdiesStyles"
import Box from "../components/box"

const CaseStusdiesPage = ({ data }) => {
  const { nodes } = data.allWordpressWpCaseStudies
  const { title, canonical } = data.wordpressPage.yoast
  const titlePage = data.wordpressPage.title
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title ? title : "Case Studies - Dapr"}</title>
        {canonical ? (
          <link rel="canonical" href={canonical} />
        ) : (
          "Case Studies - Dapr"
        )}
      </Helmet>
      <CaseStusdiesStyled>
        <Layout>
          <section className="background__white caseStudies">
            <div className="container">
              <h1 className="heading--1">{titlePage}</h1>
              <div className="caseStudies__wapper">
                {nodes.map((item, index) => {
                  return (
                    <Box
                      key={item.id}
                      slug={item.slug}
                      heading={item.acf.case_list_heading}
                      description={item.acf.case_list_description}
                      background={item.acf.case_small_bg}
                      image={item.acf.case_logo}
                      buttonColor={item.acf.case_list_button_color}
                      buttonColorHover={item.acf.case_list_button_hover_color}
                      buttonText={item.acf.case_list_button_text}
                      url={`/case-studies/${item.slug}`}
                    />
                  )
                })}
              </div>
            </div>
          </section>
        </Layout>
      </CaseStusdiesStyled>
    </>
  )
}

export default CaseStusdiesPage

export const query = graphql`
  query CaseStusdiesPage {
    wordpressPage(title: { eq: "case studies" }) {
      title
      yoast {
        title
        opengraph_description
        opengraph_image
        opengraph_title
        metakeywords
        metadesc
        canonical
        focuskw
        linkdex
        meta_robots_adv
        meta_robots_nofollow
        meta_robots_noindex
        redirect
        twitter_image
        twitter_title
        twitter_description
      }
    }
    allWordpressWpCaseStudies {
      nodes {
        id
        slug
        acf {
          case_list_button_color
          case_list_button_hover_color
          case_list_button_text
          case_list_description
          case_list_heading
          case_logo {
            source_url
            title
            localFile {
              childImageSharp {
                fixed(width: 240, quality: 90) {
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                  width
                  height
                }
              }
            }
          }
          case_small_bg {
            source_url
            title
            localFile {
              childImageSharp {
                fixed(width: 420, quality: 90) {
                  srcSetWebp
                  srcWebp
                  srcSet
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
