import styled from "styled-components"

const BoxStyled = styled.div`
  opacity: 0;
  transform: scale(0.4);
  max-width: 415px;
  background: #fafafa;
  transition: 0.3s all;
  margin-right: 50px;
  margin-bottom: 40px;
  @media (max-width: 1400px) {
    max-width: 333px;
  }
  @media (max-width: 1180px) {
    max-width: 280px;
    margin-right: 20px;
  }
  @media (max-width: 940px) {
    max-width: 100%;
    margin-right: 0;
    padding-bottom: 40px;
  }
  &:last-of-type {
    @media (max-width: 940px) {
      padding-bottom: 0;
    }
  }
  .box__background {
    @media (max-width: 1400px) {
      max-width: 100%;
    }
  }
  &:hover {
    .box__background {
      img {
        transition: 0.3s all linear;
        transform: scale(1.3) rotate(5deg);
      }
    }
  }
  &.show {
    opacity: 1;
    transform: scale(1);
  }
  &:last-of-type {
    margin-right: 0;
  }
  .box {
    &__header {
      position: relative;
    }
    &__heding {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      padding: 40px 0 30px;
      a {
        text-decoration: none;
        color: #191c1d;
      }
    }
    &__body {
      max-width: 300px;
      margin: 0 auto;
      color: #191c1d;
      @media (max-width: 540px) {
        padding: 0 10px;
      }
    }
    &__background {
      img {
        transition: 0.3s all linear !important;
      }
    }
    &__logo {
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        @media (max-width: 1180px) {
          left: 50% !important;
          transform: translateX(-50%);
          object-fit: contain !important;
          max-width: 70%;
          margin: 0 auto;
          display: block;
        }
      }
    }
    &__button {
      padding: 30px 0;
    }
    &__description {
    }
  }
`

export default BoxStyled
